




































































import { defineComponent, ref, inject, computed } from '@vue/composition-api';
import {
  questionIsBookmarked,
  questionIsDisliked,
  questionIsLiked,
  questionIsFlagged
} from '@/composables/useForum';
import { useUserState } from '@/store';
import CommentSection from './m-comment-section.vue';

export default defineComponent({
  name: 'MQuestion',
  components: { CommentSection },
  props: {
    question: {
      required: true,
      type: Object
    },
    adkValue: {
      required: true,
      type: Object
    },
    userType: {
      type: String,
      default: ''
    },
    isCommenting: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const { user } = useUserState(['user']);
    const openPanels = ref<number | null>(null);
    const toggleComments = () => {
      if (openPanels.value) openPanels.value = null;
      else openPanels.value = 1;
    };

    const adkData = computed(() => {
      return props.adkValue;
    });

    return {
      questionIsLiked,
      questionIsBookmarked,
      questionIsDisliked,
      questionIsFlagged,
      openPanels,
      toggleComments,
      readonly,
      adkData
    };
  }
});
