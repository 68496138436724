






















































import { defineComponent, ref, computed, inject } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { useUserState } from '@/store';

export default defineComponent({
  name: 'CommentSection',
  components: {
    MComment: () => import('@/components/molecules/program-faq/m-comment.vue')
  },
  props: {
    question: {
      required: true,
      type: Object
    },
    studentAdkData: {
      required: true,
      type: Object
    },
    userType: {
      required: true,
      type: String
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const { user } = useUserState(['user']);
    const commentInput = ref('');
    const numComments = ref(5);
    const visibleComments = computed(() => {
      const comments = props.question.comments.slice();
      comments.sort((a, b) => b.likes - a.likes);
      return comments.slice(0, numComments.value);
    });

    const comment = () => {
      if (commentInput.value.length > 0) {
        const time = new Date().toTimeString();
        ctx.emit('postComment', props.question._id, {
          _id: new ObjectId(),
          text: commentInput.value,
          time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
            return ` ${contents
              .split(' ')
              .map((v: string) => v.charAt(0))
              .join('')}`;
          }),
          avatar: user.value?.profile ? user.value?.profile.small : '',
          likes: 0,
          flags: 0
        });
        commentInput.value = '';
      }
    };
    return {
      commentInput,
      numComments,
      visibleComments,
      comment,
      readonly,
      user
    };
  }
});
